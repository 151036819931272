import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@mui/material";
import Button from "../Input/Button";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import axiosClient from "../../api/axiosClient";
import { registerSchema } from "../schemas";
const btnstyles = {
  // "&.MuiButton-root": {
  //   border: "2px white solid",
  // },
  "&.MuiButton-text": {
    color: "grey",
  },
  "&.MuiButton-contained": {
    color: "white",
  },
  "&.MuiButton-outlined": {
    color: "white",
  },
};
// TODO: Add validation phone number
const REGISTER_URL = "/api/auth/register";
const SignUp = ({ white }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const [images, setImages] = useState([]);
  const [image, setImage] = useState<string | undefined>("");
  const [phone, setPhone] = useState("");
  const [info, setInfo] = useState<MuiTelInputInfo>();
  const handlePhoneChange = (newPhone: string, info: MuiTelInputInfo) => {
    setPhone(newPhone);
    setInfo(info);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Function for converting images to base64
  const convert2base64 = (files: any) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = () => {
          var base64 = reader.result?.toString();
          var imageUrl = base64!.split(",");

          setImage(imageUrl[1]);
        };
      }
    } catch (error) {}
  };

  return (
    <>
      {white ? (
        <Button
          variant="contained"
          sx={btnstyles}
          onClick={handleClickOpen}
        >
          Register
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          onClick={handleClickOpen}
        >
          Register
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle textAlign="center">Register</DialogTitle>
        <DialogContent>
          <Container
            component="main"
            maxWidth="xs"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Formik
                validationSchema={registerSchema}
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  passwordConfirmation: "",
                  phone: "",
                  countryCode: "",
                  image: {
                    fileContentBase64: "",
                    fileName: "Profile",
                  },
                }}
                onSubmit={async (
                  values: {
                    firstName: any;
                    lastName: any;
                    email: any;
                    password: any;
                    passwordConfirmation: any;
                    phone: any;
                    countryCode: any;
                    image: any;
                  },
                  { setSubmitting }: any
                ) => {
                  const data = {
                    password: values.password,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phone: phone,
                    countryCode: info?.countryCode,
                    // image: {
                    //   fileContentBase64: image,
                    //   fileName: "Profile",
                    // },
                  };
                  const response = await axiosClient
                    .post(REGISTER_URL, data)
                    .then((res: any) => {
                      enqueueSnackbar(
                        t(
                          "Successfully created your account, please check for email for verification.1"
                        ),
                        {
                          variant: "success",
                          preventDuplicate: true,
                        }
                      );

                      enqueueSnackbar(res.data.message, {
                        variant: "info",
                        preventDuplicate: true,
                        persist: true,
                      });
                      setOpen(false);
                    })
                    .catch((error) => {
                      enqueueSnackbar(error.response.data.message, {
                        variant: "error",
                        preventDuplicate: true,
                      });
                    });
                }}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  isValidating,
                  submitForm,
                }) => (
                  <Form>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        name="firstName"
                        type="firstName"
                        label="First Name"
                        margin="normal"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        name="lastName"
                        type="lastName"
                        label="Last Name"
                        margin="normal"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        type="email"
                        label="Email"
                        name="email"
                        margin="normal"
                      />

                      <MuiTelInput
                        value={phone}
                        onChange={handlePhoneChange}
                        label="Phone"
                        required
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        type="password"
                        label="Password"
                        name="password"
                        margin="normal"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        type="password"
                        label="Confirm Password"
                        name="passwordConfirmation"
                        margin="normal"
                      />
                    </FormControl>

                    <LoadingButton
                      variant="contained"
                      fullWidth
                      size="large"
                      loading={isSubmitting || isValidating}
                      sx={{
                        marginTop: "1rem",
                        backgroundColor: "primary.main",
                        color: "#fff",
                      }}
                      type="submit"
                    >
                      {t("Sign Up.1")}
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SignUp;
